<template>
    <div :class="['nd__main-container',bgcolor]">
        <tipo-juego-card 
        v-for="item in tipos_juego"
        :key="item.name"
        :tipojuego="item.name"
        :bgcolor="item.bgcolor"
        :iconjuego="item.icon"
        :niveljuego="nronivel"
        ></tipo-juego-card>
    </div>
</template>

<script>
import TipoJuegoCard from '../components/TipoJuegoCard.vue'
export default {
    name:'NivelDetalle',
    props:['nronivel','bgcolor'],
    components:{
       TipoJuegoCard
    },
    data:()=>({
        tipos_juego:[
            {
                name:'Percepción',
                icon:'self-awareness-icon',
                bgcolor:'percepcion'
            },
            {
                name:'Cálculo',
                icon:'calculadora-icon',
                bgcolor:'calculo'
            },
            {
                name:'Razonamiento',
                icon:'psicologia-icon',
                bgcolor:'razonamiento'
            },
            {
                name:'Lenguaje',
                icon:'lenguaje-icon',
                bgcolor:'espacio'
            },
            {
                name:'Memoria',
                icon:'memoria-icon',
                bgcolor:'memoria'
            }
        ]
    })

}
</script>
<style scoped>
.n1{
    background-color: #9C04C4;
}
.n2{
    background-color: #1A0271;
}
.n3{
    background-color: #9E1981;
}
.n4{
    background-color: #B26B65;
}
.n5{
    background-color: #285D5C;
}
@media (min-width: 900px) and (orientation: landscape) {
    .nd__main-container{
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-template-rows: 20% 20%;
        align-content: center;
    }
}
</style>