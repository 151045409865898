<template>
    <div class="tipojuegocard-container">
        <div :class="['box',bgcolor]" @click="cambiarRuta()">
            <div class="box__title">
                {{tipojuego}}
            </div>
            <div :class="['box__icon',iconjuego]">

            </div>
        </div>
    </div>
</template>
<script>
import {mapMutations} from 'vuex'
export default {
    name:'TipoJuegoCard',
    props:['tipojuego','bgcolor','iconjuego','niveljuego'],
    data:()=>({
        rutaIcon:'../assets/icons/psicologia.svg'
    }),
    methods:{
        ...mapMutations(['changeTipoJuegoSelected']),
        cambiarRuta(){
            this.changeTipoJuegoSelected(this.tipojuego)
            this.$router.push('/juegos')
        }
    }
}
</script>
<style scoped>
.tipojuegocard-container{
    display: flex;
    justify-content: center;
    margin-top: 0.5em;
    margin-bottom: 1em;
}
.box{
    width: 95%;
    border-radius: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: all .5s;
}
.box:hover{
    cursor: pointer;
    box-shadow: 0px 0px 10px white;
}
.box__title{
    margin-left: 1em;
    color:white;
    font-size: 2em;
}
.box__icon{
    width: 100px;
    height: 100px;
    background-repeat: no-repeat;
    background-position: center;
}
.psicologia-icon{
    background-image: url('../assets/icons/psicologia.svg');
}
.calculadora-icon{
    background-image: url('../assets/icons/calculadora.svg');
}
.self-awareness-icon{
    background-image: url('../assets/icons/self-awareness.svg');
}
.memoria-icon{
    background-image: url('../assets/icons/memoria.svg');
}
.cube-icon{
    background-image: url('../assets/icons/3d-cube.svg');
}
.lenguaje-icon{
    background-image: url('../assets/icons/lenguaje.svg');
    background-size: 100% 100%;
}
.percepcion{
    background-color:#E2993B;
}
.calculo{
    background-color: #B91000;
}
.razonamiento{
    background-color: #580380;
}
.espacio{
    background-color: #0468BF;
}
.memoria{
    background-color: #055813;
}
</style>