<template>
    <div :class="[nivelColor,'main-c']">
        <nav-bar sizetitle="sm" :nivel="nivelSelected"></nav-bar>
        <div class="game-type-container">
            <div class="student-container">
                <div class="text-student-container">
                    <div class="text-student">
                        Seleccione el tipo de inteligencia
                    </div>
                </div>
                <img class="student-cramer" src="../assets/characters/girl-cramer.png" alt="student cramer">
            </div>
            <nivel-detalle :nronivel="nivelSelected" ></nivel-detalle>
        </div>
        <div class="nd__spacer"></div>
    </div>
</template>

<script>
import NavBar from '../components/NavBar.vue'
import NivelDetalle from '../components/NivelDetalle.vue'
import {mapState} from 'vuex'
export default {
    components:{
        NavBar,
        NivelDetalle
    },
    computed:{
        ...mapState(['nivelSelected','nivelColor'])
    },
    mounted(){
        if(!this.nivelSelected||!this.nivelColor)
        {
            this.$router.replace('/home')
        }
    }
}
</script>
<style scoped>
.main-c{
    width: 100%;
    min-height: 100vh;
}
.n1{
    background-color: #9C04C4;
}
.n2{
    background-color: #1A0271;
}
.n3{
    background-color: #9E1981;
}
.n4{
    background-color: #B26B65;
}
.n5{
    background-color: #285D5C;
}
.nd__spacer{
    display: block;
    height: 5em;
}
.student-container{
    display: none;
}
.student-cramer{
    display: none;
}


@media (min-width: 900px) and (orientation: landscape) {
    .main-c{
        background-image: url('../assets/bg-desktop/bg-home.svg');
        background-position-x: -20px;
        background-position-y: -200px;
        background-size: 100%;
        background-repeat: no-repeat;
    }
    .game-type-container{
        width: 100%;
        display: grid;
        grid-template-columns: 30% 70%;
        grid-template-rows: 100%;
        justify-items: center;
    }
    .student-container{
        display: block;
        width: 80%;
        position: relative;
    }
    .student-cramer{
        display: block;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .text-student-container{
        position: absolute;
        right: -60%;
        top:10%;
        width: 80%;
        background-color: white;
        border-radius: 30px;
    }
    .text-student{
        font-size: 2em;
        padding: 0.5em 1em;
    }
    .nd__spacer{
        display: none;
    }
}
</style>